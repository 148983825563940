import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.

window.jQuery = $;

require('foundation-sites');
require('owl.carousel/dist/owl.carousel.min.js');
require('./registrationFormPage');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

$(document).foundation();
 
$('[data-tabs]').on('change.zf.tabs', function() {
  var self = $(this);
  
  var activeTabs = self.find('.is-active');
  if (activeTabs.length > 1) {
      //If there are multiple tabs active, collapse all the tabs except for the first active one
      activeTabs.each(function(index, tab) {
          if (index > 0) {
              self.foundation('_collapseTab', $(tab));
          }
      });
  } else if (activeTabs.length < 1) {
      //If no tabs are open, open the first one
      self.foundation('_openTab', self.find('.tabs-title').first());
  }
});


 /*----------------------------------------------------- */
      /* Adds Click Event on hover in primary menu */
/*----------------------------------------------------- */
  $('#site-menu .tabs-title').on('mouseenter', function() {
    var self = $(this);
    self.find('a').click();
  });

  $('[data-tabs]').on('mouseleave', function() {
    var self = $(this);
    self.foundation('up', $('#yourAccordion .accordion-content'));
  });


/*----------------------------------------------------- */
             /* Close High Alert */
/*----------------------------------------------------- */
  $('.alert-high').on('mousedown', function() {
    $(this).hide();
  });


/*----------------------------------------------------- */
             /* Back to top functionality */
/*----------------------------------------------------- */
/* var btn = $('#back-to-top-button');

$(window).onscroll(function() {
  if ($(window).scrollTop() > 300) {
    btn.addClass('show');
    console.log("< 300");
  } else if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight){
    btn.removeClass('show');
    console.log("bottom");
  } else {
    btn.removeClass('show');
    console.log("> 300");
  }
});

btn.on('click', function(e) {
  e.preventDefault();
  $('html, body').animate({scrollTop:0}, '300');
});
 */
/*----------------------------------------------------- */
      /* Scroll to anchor point functionality */
/*----------------------------------------------------- */
$(".scroll-to").click(function(e) {
  e.preventDefault();
  var aid = $(this).attr("href");
  $('html,body').animate({scrollTop: $(aid).offset().top},'slow');
});

function hasTouch() {
    return 'ontouchstart' in document.documentElement
           || navigator.maxTouchPoints > 0
           || navigator.msMaxTouchPoints > 0;
}

if (hasTouch()) { // remove all :hover stylesheets
    try { // prevent exception on browsers not supporting DOM styleSheets properly
        for (var si in document.styleSheets) {
            var styleSheet = document.styleSheets[si];
            if (!styleSheet.rules) continue;

            for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                if (!styleSheet.rules[ri].selectorText) continue;

                if (styleSheet.rules[ri].selectorText.match(':hover')) {
                    styleSheet.deleteRule(ri);
                }
            }
        }
    } catch (ex) {}
}

// Detect request animation frame
var scroll = window.requestAnimationFrame ||
    // IE Fallback
    function(callback){ window.setTimeout(callback, 1000/60)};
    var elementsToShow = document.querySelectorAll('.show-on-scroll'); 

function loop() {
    Array.prototype.forEach.call(elementsToShow, function(element){
    if (isElementInViewport(element)) {
        element.classList.add('is-visible');
    } else {
        element.classList.remove('is-visible');
    }
    });

    scroll(loop);
}

// Call the loop for the first time
loop();

// Helper function from: http://stackoverflow.com/a/7557433/274826
function isElementInViewport(el) {
  // special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }
  var rect = el.getBoundingClientRect();
  return (
    (rect.top <= 0
      && rect.bottom >= 0)
    ||
    (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight))
    ||
    (rect.top >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
  );
}

$(function() {
  // Filters toggle
  $('.filter-toggle a').click(function(e) {
    e.preventDefault();
    $(this).parent().parent().toggleClass('expanded');
  });

});
(function ($) {

/*----------------------------------------------------- */
                    /* Carousels */
/*----------------------------------------------------- */



// Image Grid Slideshow - Adds a counter for the number of images
$('.image-gallery .owl-carousel').owlCarousel({
    margin: 20,
    items: 1,
    loop: false,
    nav: true,
    navText: ["<span class='show-for-sr'>Previous Slide</span><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' role='presentation'><path class='lines' d='M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0Zm0,95.83A45.83,45.83,0,1,1,95.83,50,45.84,45.84,0,0,1,50,95.83Z'/><circle class='body' cx='50.01' cy='49.93' r='45.83' transform='translate(-15.26 77.03) rotate(-67.5)'/><polygon class='arrow' points='55.94 33.69 36 50 55.9 66.31 57.74 64.31 40.24 50 57.74 35.74 55.94 33.69'/></svg>", "<span class='show-for-sr'>Next Item</span><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' role='presentation'><path class='lines' d='M50.3,0a50,50,0,1,0,50,50A50,50,0,0,0,50.3,0Zm0,95.83A45.83,45.83,0,1,1,96.13,50,45.84,45.84,0,0,1,50.3,95.83Z' transform='translate(-0.3)'/><circle class='body' cx='50.31' cy='49.93' r='45.83' transform='translate(-15.37 77.3) rotate(-67.5)'/><polygon class='arrow' points='40.63 66.31 60.57 50 40.67 33.69 38.83 35.7 56.33 50 38.83 64.26 40.63 66.31'/></svg>"],
    dots: true,
    smartSpeed: 750,
    navContainerClass: "owl-nav grid-container medium",
    onInitialized: counter, // When the plugin has initialized.
    onTranslate: counter, // When the translation of the stage has finished.
    onChanged: counter
  });
  
  // Image Grid Slideshow - Adds a counter for the number of images
  $('.image-grid .owl-carousel').owlCarousel({
    margin: 40,
    loop: false,
    nav: true,
    navText: ["<span class='show-for-sr'>Previous Slide</span><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' role='presentation'><path class='lines' d='M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0Zm0,95.83A45.83,45.83,0,1,1,95.83,50,45.84,45.84,0,0,1,50,95.83Z'/><circle class='body' cx='50.01' cy='49.93' r='45.83' transform='translate(-15.26 77.03) rotate(-67.5)'/><polygon class='arrow' points='55.94 33.69 36 50 55.9 66.31 57.74 64.31 40.24 50 57.74 35.74 55.94 33.69'/></svg>", "<span class='show-for-sr'>Next Item</span><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' role='presentation'><path class='lines' d='M50.3,0a50,50,0,1,0,50,50A50,50,0,0,0,50.3,0Zm0,95.83A45.83,45.83,0,1,1,96.13,50,45.84,45.84,0,0,1,50.3,95.83Z' transform='translate(-0.3)'/><circle class='body' cx='50.31' cy='49.93' r='45.83' transform='translate(-15.37 77.3) rotate(-67.5)'/><polygon class='arrow' points='40.63 66.31 60.57 50 40.67 33.69 38.83 35.7 56.33 50 38.83 64.26 40.63 66.31'/></svg>"],
    dots: false,
    smartSpeed: 750,
    navContainerClass: "owl-nav grid-container medium",
    onInitialized: counter, // When the plugin has initialized.
    onTranslate: counter, // When the translation of the stage has finished.
    responsive: {
      0: {
        items: 1,
        slideBy: 1
      }
    }
  });

  // Image Grid Slideshow - Adds a counter for the number of images
  $('.video-widget .owl-carousel').owlCarousel({
    margin: 20,
    loop: false,
    nav: false,
    dots: false,
    smartSpeed: 750,
    navContainerClass: "owl-nav grid-container medium",
    onInitialized: counter, // When the plugin has initialized.
    onTranslate: counter, // When the translation of the stage has finished.
    responsive: {
      0: {
        items: 1,
        slideBy: 1
      }
    }
  });

    // Image Grid Slideshow - Adds a counter for the number of images
  $('.media-grid .owl-carousel').owlCarousel({
    margin: 40,
    loop: false,
    nav: true,
    navText: ["<span class='show-for-sr'>Previous Slide</span><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' role='presentation'><path class='lines' d='M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0Zm0,95.83A45.83,45.83,0,1,1,95.83,50,45.84,45.84,0,0,1,50,95.83Z'/><circle class='body' cx='50.01' cy='49.93' r='45.83' transform='translate(-15.26 77.03) rotate(-67.5)'/><polygon class='arrow' points='55.94 33.69 36 50 55.9 66.31 57.74 64.31 40.24 50 57.74 35.74 55.94 33.69'/></svg>", "<span class='show-for-sr'>Next Item</span><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' role='presentation'><path class='lines' d='M50.3,0a50,50,0,1,0,50,50A50,50,0,0,0,50.3,0Zm0,95.83A45.83,45.83,0,1,1,96.13,50,45.84,45.84,0,0,1,50.3,95.83Z' transform='translate(-0.3)'/><circle class='body' cx='50.31' cy='49.93' r='45.83' transform='translate(-15.37 77.3) rotate(-67.5)'/><polygon class='arrow' points='40.63 66.31 60.57 50 40.67 33.69 38.83 35.7 56.33 50 38.83 64.26 40.63 66.31'/></svg>"],
    dots: false,
    smartSpeed: 750,
    navContainerClass: "owl-nav",
    onInitialized: counter, // When the plugin has initialized.
    onTranslate: counter, // When the translation of the stage has finished.
    responsive: {
        0: {
            items: 1,
            slideBy: 1
        },

        1024: {
            items: 2,
            autoWidth: false,
            autoHeight: true,
        }
    }
  });

/*----------------------------------------------------- */
               /* Counter on carousels */
/*----------------------------------------------------- */
  function counter(event) {
    var element = event.target; // DOM element, in this example .owl-carousel
    var items = event.item.count; // Number of items
    var item = event.item.index + 1; // Position of the current item
  
    // it loop is true then reset counter from 1
    if (item > items) {
      item = item - items
    }
  
    $('.current').html('0' + item);
    $('.total').html('0' + items);
  }
  })(jQuery);

