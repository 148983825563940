
 var baseURL = '' ;
$(document).ready(function(){

 var baseURL = '' ;
	$("#join-form").validate({
	   rules:{
			first_name :{ required : true },
			admin_email : { required : true, email : true, remote : { url : baseURL + "checkEmailExists", type :"post"} },
			password : { required : true },
			c_password : {required : true, equalTo: "#password"},
			
		},
		messages:{
			first_name :{ required : "Name  is required" },
			admin_email : { required : "This field is required", admin_email : "Please enter valid email address", remote : "Email already taken" },
			password : { required : "This field is required" },
			c_password : { equalTo: "Please enter same password" }
					
		}
	})

	$('#jRegister').click(function() {
		
		
	 $("#join-form").valid(); 

	  // This is not working and is not validating the form
	  if ($('#join-form').valid()) {
	  //	alert('submit-validated-form');
	  	RegisterUser();
    // do your thing
       }


	});

});


	



$(document).ready(function() 
{

		ShowRegistrationFormStepTwo(); return true;
});


function ShowRegistrationFormStepTwo()
{
    $('.join_step2').show();
	$('#signup_step3_div').css("display","none !important");
}





function RegisterUser()
{
		

	// var result = true;
	// $(".required").each(function(event) {
 //        var check = $(this).val();
 //        if(check == '') {
	// 		event.preventDefault();
 //            result = false;
 //        }
 //    });
	// if (!result) {
	// 	// return false;
	// }
	

	$.ajax({
		url:  '/join/saveUserDetail',
		global: false,
		type: "POST",
		data: $("#join-form").serialize(),
		success: function(msg) 
		{
			if (msg == 'user_insert_success') {
				
              $('.join_step2').hide();
              $('.hn1 h1').html("Almost Done");
              $('#signup_step3_div').show();
				
			} else {
				$('#error_span').html(msg.ErrorText);
				//alert('Error: ' + msg.ErrorText);
			}
		},
		error: function(msg) 
		{
			

			//alert('Error: ' + msg.status + ', ' + msg.statusText);
		}
	});

	return false;
}


function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}